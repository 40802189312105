import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { CONFIG } from '../Core/config';
import { UserInfoResponse } from '../shared/models/users.model';
import { ServerService } from '../Core/server.service';
import { ApiService } from '../Services/api.service';
import { GridStatePreferencesService } from './gridStatePreferences.service';
import { MsalService } from '@azure/msal-angular';
import { gridsettings } from '../../assets/data/GridSettingsID';

@Injectable({providedIn: 'root'})
export class UserInfoService {
    public loggedinUserData: UserInfoResponse;
    public permissionList: any;
    private userInfo: BehaviorSubject<string> = new BehaviorSubject<string>('');
    updateUserInfo$ = this.userInfo.asObservable();

    constructor(private serverService: ServerService, private http: HttpClient,
        private gridStatePreferencesService: GridStatePreferencesService, private authService: MsalService,
        private apiService: ApiService) {
    }
    getuserddetails(UserId) {
        sessionStorage.setItem('UserInfoCaptured', 'true');
        if (UserId) {
            this.apiService.getUser(UserId).subscribe(userData => {
                userData = userData;
                this.loggedinUserData = userData;
               sessionStorage.setItem('UserInfo', JSON.stringify(userData));
            //    if (window.localStorage.getItem(gridsettings.containerStatus) === null) {
            //        this.gridStatePreferencesService.getStatePersistence();
            //    }
            });
            this.apiService.getUserPermissions(UserId).subscribe((permissions: any) => {

            this.permissionList =  permissions.map(v => v.toUpperCase());
             sessionStorage.setItem('PermissionList', JSON.stringify( permissions.map(v => v.toUpperCase())));
                    // (this.permissionList)
 			// window.location.reload();
            });
        }
    }

    getUserAccessID() {
        // return this.authService.getUser().idToken['emails'][0];
        return JSON.parse(sessionStorage.getItem('UserInfo')).UserID;
    }

    getUserAccessEmail() {
        let activeAccount = this.authService.instance.getActiveAccount();

        let email = activeAccount?.username;// "rajupotharaju.potharaju@carrier.com";//this.authService.getAccount().idToken['emails'][0];
        return this.checkEmail(email);
    }

    checkEmail(email) {
        let validEmail = email.includes('#') ? email.split('#')[1] : email;
            return validEmail;
    }

    public updateUser(data) {
        if (data != null) {
            this.userInfo.next(data);
        }
    }
}


// import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
// import { CommonModule } from '@angular/common';
// import { TRANSLATION_PROVIDERS, TranslatePipe } from 'src/language-translation';

// @NgModule({
//     imports: [
//         CommonModule,
//         // TranslatePipe,
//     ],
//     exports: [TranslatePipe],
//     providers: [TRANSLATION_PROVIDERS],
//     schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
// })
export class TranslationModule { }

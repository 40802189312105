import { Injectable } from '@angular/core';
import { timeZonesList } from '../../assets/data/timezones';

@Injectable()

export class UnitConverterService {
        public timezoneDataList = timeZonesList
        constructor() { }
        public dateFormat = 'dd/MM/yyyy';
        public timeZone: string;
        public offset = 0;
        public tempConvert(field: string, data1: object, column: object) {
                if (!isNaN(data1[field]) && data1[field] != '') {
                        const temperature = (data1[field] * 1.8) + 32;
                        return Number(temperature).toFixed(2);
                }
                return data1[field];
        }

        public tempFixed(field: string, data1: object, column: object) {
                if (!isNaN(data1[field]) && data1[field] != '') {
                        const temperature = data1[field];
                        return Number(temperature).toFixed(2);
                }
                return data1[field];
        }


        public tempConverttoFixed(data1: any) {
                if (!isNaN(data1) && data1 != '') {
                        const temperature = (data1 * 1.8) + 32;
                        return Number(temperature).toFixed(2);
                }
                return data1;
        }

        public temptoFixed(data1: any): any {
                if (!isNaN(data1) && data1 != '') {
                        let data = Number(Number(data1).toFixed(2));
                        return data;
                }
                return data1;
        }

        public tempConvertForCon(value: any, dec: number = 2) {
                if (!isNaN(value) && value != '') {
                        const temperature = (value * 1.8) + 32;
                        return Number(temperature).toFixed(dec);
                }
                return value;
        }

        public pressureConvert(field: string, data1: object, column: object) {
                if (!isNaN(data1[field]) && data1[field] != '') {
                        const press = data1[field] * 0.0689476;
                        return Number(press).toFixed(2);
                }
                return data1[field];
        }

        public pressureConvertion(data) {
                if (!isNaN(data) && data != '') {
                        const press = data * 0.0689476;
                        return press.toFixed(2);
                }
                return data;
        }
        public pressureBarToPSIConvertion(data) {
                if (!isNaN(data) && data != '') {
                        const press = data * 14.5;
                        return press.toFixed(2);
                }
                return data;
        }

        public airExchangeConvert(field: string, data1: object, column: object) {
                if (!isNaN(data1[field]) && data1[field] != '') {
                        const press = data1[field] * 0.588578;
                        return Number(press).toFixed(2);
                }
                return data1[field];
        }

        public airExchangeCon(value) {
                // if (value != null) {
                //         const press = value * 0.588578;
                //         return press.toFixed(2);
                // }
                return value;
        }

        public setDateFormat(value: string) {
                this.dateFormat = value;
        }

        public setTimeZone(value: string) {
                this.timeZone = value;
                const getValue = this.timezoneDataList.find((ele: any) => {
                        return ele.timezone === value;
                });
                let timeZoneText = String(getValue['text']);
                const offsetString = timeZoneText.slice(4, 10);
                // spliting the offset string and calculating the time difference
                const splitString = offsetString.split(':');
                if (splitString.length >= 2) {
                        const minuteOffset = (Number(splitString[1]) * 50) / 30;
                        this.offset = Number(splitString[0]) * 100 + minuteOffset;
                } else {
                        this.offset = 0;
                }

        }

        getTimeZoneName() {
                return this.timeZone;
        }
        public convertTimeZone(date: any) {
                let dt: string;
                if (typeof date == "string") {
                        if (date.lastIndexOf("Z") > 0) {
                                dt = date.split("Z")[0];
                        } else {
                                dt = date;
                        }
                }
                if (dt) {
                        const dateValue = new Date(dt).valueOf();
                        const convertedDate = new Date(dateValue + (36000 * this.offset));
                        return convertedDate;
                }
        }

        public getDateFormat() {
                if (this.dateFormat === '') {
                        this.dateFormat = 'dd/MM/yyyy';
                }
                return this.dateFormat;
        }

        public speedConvert(value) {
                if (value != null) {
                        const speed = value * 1.60934;
                        return Number(speed).toFixed(2);
                }
                return value;
        }

        // public getControllerType(value) {
        //         if (value && value != null && value != "") {
        //             return +value >= 5700 && +value <= 5799 ? true: false;
        //         }
        //         return;
        // }
}


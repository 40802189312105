import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import  jQuery from 'jquery'
@Injectable()
export class LoadingService {
    RenderModalHandler: Subject<string> = new Subject();
    enableFlag = true;
    constructor() {
    }
    show() {
        if(document.getElementById('preloader')){
        document.getElementById('preloader').style['display'] = 'block'; }
        jQuery('.overlay').show();
    }
    hide() {
        if(document.getElementById('preloader')){
        document.getElementById('preloader').style['display'] = 'none'; }
        jQuery('.overlay').hide();
    }
    showloadingText() {
        document.getElementById('indexdiv').style['display'] = 'block';
        // jQuery('.overlay').show();
    }
    hideloadingtext() {
        document.getElementById('indexdiv').style['display'] = 'none';
        // jQuery('.overlay').hide();
    }
    setRefreshVal(val){
        this.enableFlag = val;
    }
    getRefreshVal(){ return this.enableFlag;}
}


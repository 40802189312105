import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { ServerService } from '../../Core/server.service';
import { ApiService } from '../../Services/api.service';
import { TreeviewItem, TreeItem } from 'ngx-treeview';
import { SuccessFailureService } from '../Services/successFailure.service';
import { UserInfoService } from '../../Services/userInfo.service';

@Injectable()
export class CompanyService {
  public totalList: TreeviewItem[];
  constructor(
    private serverService: ServerService,
    private authService: MsalService,
    public successFailureService: SuccessFailureService,
    private http: HttpClient,
    private apiService: ApiService,
    private userInfoService: UserInfoService
  ) {}
  public itemData;
  getCompany() {
    this.totalList = [];
    this.apiService
      .getUserCompanies(this.userInfoService.getUserAccessID())
      .subscribe((response: any) => {
        if (this.successFailureService.successFailureReturn(response)) {
          if (response.CompanyNames) {
            sessionStorage.setItem(
              'CompanyTreeDropdownList',
              JSON.stringify(response.CompanyNames)
            );
          }
        }
      });
  }
}

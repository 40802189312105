import { Component, ViewEncapsulation, OnInit, OnDestroy, Inject, HostListener } from '@angular/core';
import { DOCUMENT, NgClass, NgIf, NgStyle } from '@angular/common';
import { environment } from '../environments/environment';
import { filter, map, Subject, Subscription, takeUntil } from 'rxjs';
import { Router, RouterOutlet, RouterModule } from '@angular/router';
import { AppState } from './app.state';
// import { AppInsightService } from './Services/AppInsights-Service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { FormsModule } from '@angular/forms';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, IdTokenClaims, InteractionStatus, InteractionType, PopupRequest, PromptValue, RedirectRequest } from '@azure/msal-browser';
import { MsalCustom } from './Services/MsalCustom.service';
import { b2cPolicies } from './auth.config';
export declare enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}
const triplinkConfig = environment.tripLinkConfig;

// declare ga as a function to access the JS code in TS
declare let ga: Function;
type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

@Component({
  selector: 'TripLink-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  providers: [],
  imports: [RouterOutlet, RouterModule, NgClass, NgStyle, FormsModule, NgIf]
})

export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  isBrowserNotCompatable: boolean;
  loadRouting: boolean = false;
  loginDisplay = false;
  hasUser = false;
  showContent = false;
  CompanyTreeDropdownRendered = false;
  userloaded = false;
  permissionsLoaded = false;
  public userLoggedInfo = false;
  isshowTerms = false;
  versionNumber: any;
  public isMenuCollapsed = false;
  public isIframe: boolean;
  private loginFailureSubscription: Subscription;
  private loginSuccessSubscription: Subscription;
  private acquireTokenSuccessSubscription: Subscription;
  private acquireTokenFailureSubscription: Subscription;
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
      enableAutoRouteTracking: true,
      correlationHeaderExcludedDomains: ['*.googleapis.com']
    }
  });
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalCustom: MsalCustom,
    public router: Router,
    public _state: AppState,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    @Inject(MsalBroadcastService) private msalBroadcastService: MsalBroadcastService,
  ) {
    this.isIframe = window !== window.parent && !window.opener;
    // this.userLoggedInfo = false;
  }
  // @HostListener('document:click', ['$event', '$event.target'])
  // onClick(event: MouseEvent, targetElement: HTMLElement): void {
  //    let cmp_dr: any = document.getElementById("cmp_dr");
  //       let isChild = cmp_dr.childNodes[0].classList;
  //      if (isChild.length>0 && (targetElement.classList[0] != 'btn' && targetElement.classList[1] !=  'dropdown-toggle' && targetElement.classList[2] != 'btn-outline-secondary')  ) {
  //           cmp_dr.childNodes[0].classList.remove('show');
  //           cmp_dr.childNodes[0].childNodes[1].classList.remove('show');
  //      }
  //      let nav_g_drpdwn: any = document.getElementById("nav_g_drpdwn");
  //       let isChild1 = nav_g_drpdwn.childNodes[0].classList;
  //      if (isChild1.length>0 && (targetElement.classList[0] != 'btn' && targetElement.classList[1] !=  'dropdown-toggle' && targetElement.classList[2] != 'btn-outline-secondary')  ) {
  //       nav_g_drpdwn.childNodes[0].classList.remove('show');
  //       nav_g_drpdwn.childNodes[0].childNodes[1].classList.remove('show');
  //      }

  // }



  msaLinitialize = async () => {
    try {
      await this.authService.initialize();
    }
    catch (err) {
      console.log(err.message);
    }
  };
  ngOnInit(): void {
    this.msaLinitialize();
    this.isBrowserNotCompatable = this.msalCustom.isIEBrowser();
    if (this.isBrowserNotCompatable) {
      document.getElementById('preloader').style['display'] = 'none';
    }
    this.isIframe = window !== window.parent && !window.opener;
    this.authService.handleRedirectObservable().subscribe();

    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {

        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (idtoken.acr === b2cPolicies.names.signUpSignIn || idtoken.tfp === b2cPolicies.names.signUpSignIn) {
          this.authService.instance.setActiveAccount(payload.account);
        }

        console.log('login success', idtoken)

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        if (idtoken.acr === b2cPolicies.names.resetPassword || idtoken.tfp === b2cPolicies.names.resetPassword) {
          let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
            scopes: []
          };

          this.login(signUpSignInFlowRequest);
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Checking for the forgot password error. Learn more about B2C error codes at
        // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.resetPassword.authority,
            scopes: [],
          };

          this.login(resetPasswordFlowRequest);
        }else if(result.error){
          this.authService.logoutRedirect();
          let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
            scopes: []
          };

         return this.login(signUpSignInFlowRequest);
        }

      });

    this.authService.instance.addEventCallback((event) => {
      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        // Handle token acquisition failure, which may indicate session expiration
        this.handleSessionExpiration();
      }
    });
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout() {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
  }


  handleSessionExpiration(): void {
    // Implement your logic to handle session expiration, e.g., redirect to login page
    this.authService.logoutRedirect();
  }

  checkAndSetActiveAccount() {

    let activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    } else if (this.authService.instance.getAllAccounts().length == 0) {
      let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
        scopes: []
      };

      return this.login(signUpSignInFlowRequest);
    }

    const account = this.authService.instance.getAllAccounts()[0];
    if (account) {
      const tokenRequest = {
        account: account,
        scopes: [triplinkConfig.consentScopes[0]]
      };

      this.authService.acquireTokenSilent(tokenRequest).subscribe({
        next: (response: AuthenticationResult) => {

          sessionStorage.setItem('accessToken', response.accessToken);
          this._state.LoginSuccessCall(true);

          this.loadRouting = true;
          return true;
        },
        error: (error) => {
          console.error('Silent token acquisition failed:', error);
          this.authService.logoutRedirect();
          // this.authService.acquireTokenRedirect({
          //     scopes: [triplinkConfig.consentScopes[0]]
          // });
          let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
            scopes: []
          };

          return this.login(signUpSignInFlowRequest);

        }
      });
    }
  }


  ngOnDestroy() {
    // this.broadcastService.getMSALSubject().next(1);
    this._destroying$.next(undefined);
    this._destroying$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    // this.broadcastService.getMSALSubject().next(1);
    if (this.loginFailureSubscription) {
      this.loginFailureSubscription.unsubscribe();
    }
    if (this.loginSuccessSubscription) {
      this.loginSuccessSubscription.unsubscribe();
    }
    if (this.acquireTokenSuccessSubscription) {
      this.acquireTokenSuccessSubscription.unsubscribe();
    }
    if (this.acquireTokenFailureSubscription) {
      this.acquireTokenFailureSubscription.unsubscribe();
    }
  }
}

<div class="container">
    <div class="row">
         <div class="col-xl-4 col-md-6 col-10 mx-auto p-t-10" style="box-sizing: content-box !important;">             
             <div class="card">
                <div class="card-body text-center">
                    <img src="../../../assets/img/pagenotFound.svg" >

                    <h1 class="display-1" style="color: red">Page Not Found</h1>

                      <p class="card-title mb-0" style="font-size: large;text-align: left !important;">It seems that there is no page exists for this request.
                     Please click <a 
                     rel='nofollow' href="javascript:void(0);"
                             (click)='previous()'
                     > here </a> to go to Dashboard </p>
                   
                </div>
             </div>
         </div>
    </div>
 </div>

import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {Subject}    from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppState {

  private _data = new Subject<Object>();
  private _dataStream$ = this._data.asObservable();

  public _geo: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public  _geo$ = this._geo.asObservable();

  
  public _geoList: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public  _geoList$ = this._geoList.asObservable();

  public _loginSuccess: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public  _loginSuccess$ = this._loginSuccess.asObservable();

  

  private _subscriptions: Map<string, Array<Function>> = new Map<string, Array<Function>>();

  constructor() {
    this._dataStream$.subscribe((data) => this._onEvent(data));
  }

  notifyDataChanged(event, value) {

    const current = this._data[event];
    if (current != value) {
      this._data[event] = value;

      this._data.next({
        event: event,
        data: this._data[event]
      });
    }
  }

  notifyGeofenceChange(event, value) {
    this._geo.next({
      event: event,
      data: value
    });
  }

  notifyGeofenceListLoaded(event, value) {
    this._geoList.next({
      event: event,
      data: value
    });
  }

  LoginSuccessCall(event) {
    this._loginSuccess.next({event: event});
  }
  


  subscribe(event: string, callback: Function) {
    const subscribers = this._subscriptions.get(event) || [];
    subscribers.push(callback);

    this._subscriptions.set(event, subscribers);
  }

  _onEvent(data: any) {
    const subscribers = this._subscriptions.get(data['event']) || [];

    subscribers.forEach((callback) => {
      callback.call(null, data['data']);
    });
  }
}

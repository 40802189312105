import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

const triplinkConfig = environment.tripLinkConfig;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private msalService: MsalService,    private msalBroadcastService: MsalBroadcastService,
  ) {}

  login() {
    this.msalService.loginRedirect();

    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          // this.setLoginDisplay();
        }
      });

     

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        // this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */

    let activeAccount = this.msalService.instance.getActiveAccount();
    console.log('activeAccount',activeAccount)
    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }else if(this.msalService.instance.getAllAccounts().length == 0){
        this.loginRedirect()
      }

    const account = this.msalService.instance.getAllAccounts()[0];
    if(account){
    const tokenRequest = {
        account: account,
        scopes: [triplinkConfig.consentScopes[0]]
    };

    this.msalService.acquireTokenSilent(tokenRequest).subscribe({
        next: (response: AuthenticationResult) => {
            
            console.log('Token acquired silently:', response.accessToken);
            sessionStorage.setItem('accessToken', response.accessToken);
            return true;
        },
        error: (error) => {
            console.error('Silent token acquisition failed:', error);
            this.msalService.acquireTokenRedirect({
                scopes: [triplinkConfig.consentScopes[0]]
            });
        }
    });
  }
  }

  
  async loginRedirect() {
    await this.msalService.instance.handleRedirectPromise();
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }
  logout() {
    this.msalService.logoutRedirect();
  }

  isLoggedIn(): boolean {
    console.log('isLoggedIn method')
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  getUserProfile(): Promise<AuthenticationResult> {
    return this.msalService.instance.acquireTokenSilent({
      scopes: [triplinkConfig.consentScopes[0]]
    });
  }
}
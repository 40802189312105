import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { BroadcastService } from '@azure/msal-angular';
// import { MsalAngularConfiguration } from '@azure/msal-angular/src/msal-angular.configuration';
import { environment } from '../../environments/environment';
import { CONFIG } from '../Core/config';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  NavigationEnd,
  UrlSegment,
} from '@angular/router';
// import { MSAL_CONFIG } from '@azure/msal-angular';
// import { AuthenticationParameters, ClientAuthError, Constants, InteractionRequiredAuthError} from 'msal';
import { MSALError } from './MSALError';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LoadingService } from '../Services/loading.service';
import { filter, Subject, takeUntil } from 'rxjs';
import {
  AuthenticationResult,
  ClientAuthError,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { AuthService } from './AuthService';

const triplinkConfig = environment.tripLinkConfig;
export function loggerCallback(logLevel, message, piiEnabled) {
  'client logging' + message;
}

@Injectable()
export class MsalCustom {
  msaLinitialize = async () => {
    try {
      await this.authService.initialize();
    } catch (err) {
      console.log(err.message);
    }
  };
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public activatedRoute: ActivatedRoute,
    private http: HttpClient,
    public router: Router,
    private loadingService: LoadingService,
    private authService: MsalService,
    private authMsalService: AuthService,

    private location: Location,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.msaLinitialize();
    console.log('msalcustom')
  }
  _oauthData = {
    isAuthenticated: false,
    userName: '',
    loginError: '',
    idToken: {},
  };

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }
  login() {
    this.msaLinitialize();
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((res) => {
        console.log('InteractionStatus',this.authService.instance.getAllAccounts())
        // this.setLoginDisplay();
        // this.checkAndSetActiveAccount();
        
      });
    // this.authService.handleRedirectObservable().subscribe();
    if (
      !this.forgotPassword() &&
      !this._oauthData.isAuthenticated &&
      this.isObjectEmpty(this._oauthData.idToken)
    ) {
      //    console.log("Login Start");
      if (!this.isIEBrowser()) {
        // const authparameters: AuthenticationParameters = {};
        // authparameters.scopes = triplinkConfig.consentScopes;
        //    console.log("Before Account" , this.authService.getAccount());
        if (this.authService.instance.getActiveAccount() == null) {
          localStorage.setItem('pathname', window.location.pathname);
          console.log('Account is Null');
          if (
            sessionStorage.getItem('loginPageLoaded') == null ||
            !sessionStorage.getItem('loginPageLoaded')
          ) {
            console.log('loginPageLoaded var is empty');
            sessionStorage.setItem('loginPageLoaded', 'yes');
            sessionStorage.setItem('firstLoad', 'yes');
            // this.loginRedirect();
          } else {
            console.log('There is LoginPageReload Value');
            sessionStorage.removeItem('loginPageLoaded');
            // this.loginRedirect();

            // const tokenRequest = {
            //   scopes: [triplinkConfig.consentScopes[0]],
            // };
            // this.authService.acquireTokenRedirect(tokenRequest);
          }
        } else {
          console.log('There is Account Name');
          this.msaLinitialize();
          const account = this.authService.instance.getAllAccounts()[0];
            if(account){
          const tokenRequest = {
            account: account,
            scopes: [triplinkConfig.consentScopes[0]],
          };

          this.authService.acquireTokenSilent(tokenRequest).subscribe({
            next: (response: AuthenticationResult) => {
              console.log('Token acquired silently:', response.accessToken);
              sessionStorage.setItem('accessToken', response.idToken);

              return true;
            },
            error: (error) => {
              console.error('Silent token acquisition failed:', error);
              this.authService.acquireTokenRedirect({
                scopes: [triplinkConfig.consentScopes[0]],
              });
            },
          });
        }
        }
      }
    }
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Promise<boolean> {
    
    console.log('msalcustom canActivate')

    this.authService
      .getLogger()
      .verbose('location change event from old url to new url');
    if (this.forgotPassword('call')) {
      this.authService.instance.getConfiguration().auth.authority =
        triplinkConfig.forgotPasswordAuthority;
      // this.authService.authority.replace('b2c_1a_signupsignin', 'b2c_1a_passwordreset');
      // const authparameters: AuthenticationParameters = { };
      // authparameters.scopes = triplinkConfig.consentScopes;

      this.authService.loginRedirect();
    }
    // after changing password, redirect to login page
    else if (
      this.authService.instance.getActiveAccount() &&
      this.authService.instance.getActiveAccount().idToken ===
        triplinkConfig.forgotPasswordScope
    ) {
      sessionStorage.clear();
      this.authService.logout();
    } else if (
      this.authService.instance.getActiveAccount() &&
      !this.isObjectEmpty(this.authService.instance.getActiveAccount().idToken)
    ) {
      // this.loadingService.showloadingText();

      // if (sessionStorage.getItem('UserInfo')) {
      //   if (
      //     JSON.parse(sessionStorage.getItem('UserInfo')).EulaAcknowledgedDate ==
      //     null
      //   ) {
      //     const data = JSON.parse(sessionStorage.getItem('UserInfo'));
      //     this.router.navigate([CONFIG.ROUTE_URLS.termsconditions], {
      //       state: { data },
      //     });
      //     return false;
      //   }
      // }
      // if (sessionStorage.getItem('PermissionList')) {
      //   const permissiondata: ActivatedRouteSnapshot = null;
      //   this.router.events.subscribe((event) => {
      //     if (event instanceof NavigationEnd) {
      //       // permissiondata = this.router.routerState.snapshot.root;
      //       this.checkpermission(this.router.routerState.snapshot.root);
      //     }
      //   });
      // }
      if (sessionStorage.getItem('accessToken')) {
        return true;
      } else {
        this.loadingService.showloadingText();
        // const authparameters: AuthenticationParameters = { };
        // authparameters.scopes = triplinkConfig.consentScopes;
        const account = this.authService.instance.getAllAccounts()[0];
        if(account){
        const tokenRequest = {
          account: account,
          scopes: [triplinkConfig.consentScopes[0]],
        };

        // this.authService.acquireTokenSilent(tokenRequest).subscribe({
        //   next: (response: AuthenticationResult) => {
        //     console.log('Token acquired silently:', response);
        //     sessionStorage.setItem('accessToken', response.idToken);

        //     return true;
        //   },
        //   error: (error) => {
        //     console.error('Silent token acquisition failed:', error);
        //     this.authService.acquireTokenRedirect({
        //       scopes: [triplinkConfig.consentScopes[0]],
        //     });
        //   },
        // });
    }

        // return this.authService.acquireTokenSilent(tokenRequest).subscribe()
        //     .then((token: any) => {
        //         if (token) {
        //             this._oauthData.isAuthenticated = true;
        //             sessionStorage.setItem('accessToken', token.accessToken);
        //             this.loadingService.hideloadingtext();
        //             // this.broadcastService.broadcast('msal:acquireTokenSuccess',  authenticationResult);
        //             return true;
        //         }
        //     }, (error: any) => {
        //         // debugger;
        //         if (error instanceof ClientAuthError) {
        //             //this.authService.acquireTokenSilent(authparameters);
        //             this.authService.acquireTokenRedirect(tokenRequest);
        //             return true;
        //        }
        //         if (error instanceof InteractionRequiredAuthError) {
        //             //this.authService.loginRedirect(authparameters);
        //             this.authService.acquireTokenRedirect(tokenRequest);
        //             return true;
        //        }
        //      else
        //         {
        //         const errorParts = error.split('|');
        //         const msalError = new MSALError(errorParts[0], errorParts[1], '');
        //         // this.broadcastService.broadcast('msal:acquireTokenFailure', msalError);
        //          this.loadingService.hideloadingtext();
        //         return false;
        //     }
        //     });
      }
    } else if (this.forgotPasswordCancel()) {
      sessionStorage.clear();
      this.authService.logout();
    }
    // else if (!this.isObjectEmpty(this._oauthData.idToken)) {
    //     this.loadingService.show();
    //     const authparameters: AuthenticationParameters = { };
    //     authparameters.scopes = triplinkConfig.consentScopes;
    //     return this.authService.acquireTokenSilent(authparameters)
    //         .then((token: any) => {
    //             if (token) {
    //                 this._oauthData.isAuthenticated = true;
    //                 const authenticationResult = new AuthenticationResult(token);
    //                 // this.broadcastService.broadcast("msal:loginSuccess",  authenticationResult);
    //                 sessionStorage.setItem('accessToken', token);
    //                 this.loadingService.hide();
    //                 return true;
    //             }

    //         }, (error: any) => {
    //             const errorParts = error.split('|');
    //             const msalError = new MSALError(errorParts[0], errorParts[1], '');
    //             this.broadcastService.broadcast('msal:loginFailure', msalError);
    //             return false;
    //         });
    // }
  }
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  //   if (this.authMsalService.isLoggedIn()) {
  //     return true;
  //   } else {
  //     // this.router.navigate(['login']);
  //     return false;
  //   }
  // }
  checkpermission(node: ActivatedRouteSnapshot) {
    if (node.data['viewPermission']) {
      const permissionData = JSON.parse(
        sessionStorage.getItem('PermissionList')
      );
      if (!permissionData.includes(node.data['viewPermission'])) {
        this.router.navigate([CONFIG.ROUTE_URLS.unauthorized]);
      }
    }
    if (node.firstChild) {
      this.checkpermission(node.firstChild);
    }
  }

  // Determine if user clicked "Forgot Password"
  forgotPassword(val?) {
    const storage = this.authService['cacheStorage'];
    const authError: string = storage?.getItem('msal.login.error')
      ? storage.getItem('msal.login.error')
      : null;
    if (authError && authError.indexOf('AADB2C90118') > -1) {
      if (val == 'call') {
        storage.removeItem('msal.login.error');
        sessionStorage.removeItem('loginPageLoaded');
      }
      sessionStorage.setItem('custom.recovery.password.flow', 'true');
      return true;
    }
    return false;
  }
  forgotPasswordCancel() {
    const storage = this.authService['cacheStorage'];
    const authError: string = storage?.getItem('msal.login.error')
      ? storage.getItem('msal.login.error')
      : null;
    if (authError && authError.indexOf('AADB2C90091') > -1) {
      return true;
      sessionStorage.setItem('custom.recovery.password.flow', 'true');
    }
    return false;
  }
  private getBaseUrl(): String {
    let currentAbsoluteUrl = window.location.href;
    const currentRelativeUrl = this.location.path();
    if (this.isEmpty(currentRelativeUrl)) {
      if (currentAbsoluteUrl.endsWith('/')) {
        currentAbsoluteUrl = currentAbsoluteUrl.replace(/\/$/, '');
      }
      return currentAbsoluteUrl;
    } else {
      const index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
      return currentAbsoluteUrl.substring(0, index);
    }
  }

  isEmpty = function (str: any) {
    return typeof str === 'undefined' || !str || 0 === str.length;
  };

  isObjectEmpty(obj: Object) {
    if (obj) {
      return Object?.keys(obj).length === 0;
    } else {
      return false;
    }
  }

  /** The following method is to display 'Browser not supported message to user when application lauches in IE browser' */
  public isIEBrowser() {
    // IF IE > 10
    if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      !!document['documentMode'] === true
    ) {
      return true;
    }
    return false;
  }
}

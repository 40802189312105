export const menuItems = [
    {
        title: 'Account',
        image: 'assets/img/menu svg/profile.svg',
        originalImg: 'assets/img/menu svg/profile.svg',
        hoveredImg: 'assets/img/menu svg/nav_icons/hover/profile_hover.svg',
        selected: false,
        expanded: false,
        id: 'u_ac',
        order: 0,
        routerLink: 'settings/userprofile',
        // onclick: 'launchSearch()',
        permissions: 'ALL',

    },
    // {
    //     title: 'Search',
    //     image: 'assets/img/menu svg/Search.svg',
    //     isSearch: true,
    //     selected: false,
    //     expanded: false,
    //     permissions:'ALL',
    //     order: 0,
    //     onclick: 'launchSearch()'
    // },
    {
        title: 'Dashboard',
        routerLink: 'dashboard',
        image: 'assets/img/menu svg/Dashboard.svg',
        originalImg: 'assets/img/menu svg/Dashboard.svg',
        hoveredImg: 'assets/img/menu svg/nav_icons/hover/Dashboard_hover.svg',
        selected: false,
        expanded: false,
        order: 0,
        permissions: 'ALL',
        id: 'm_dash',
    },
    {
        title: 'Container Status',
        routerLink: 'pages/containerstatus',
        image: 'assets/img/menu svg/Containerfront-new.svg',
        originalImg: 'assets/img/menu svg/Containerfront-new.svg',
        hoveredImg: 'assets/img/menu svg/Containerfront-new.svg',
        selected: false,
        expanded: false,
        permissions: '341757D6-962A-4740-AEE1-F2509730828F',
        order: 0,
        id: 'm_cs',
    },
    {
        title: 'Trips',
        routerLink: 'trips/tripsStatus',
        image: 'assets/img/menu svg/trips.svg',
        originalImg: 'assets/img/menu svg/trips.svg',
        hoveredImg: 'assets/img/menu svg/trips.svg',
        selected: false,
        expanded: false,
        permissions: '4489B65A-2C1B-4057-AD5F-7958CC22F98D',
        order: 0,
        id: 'm_trip',
    },
     // Enable the below code for Genset
    {
        title: 'Genset Status',
        routerLink: 'pages/gensetstatus',
        image: 'assets/img/menu svg/Genset_white.svg',
        originalImg: 'assets/img/menu svg/Genset_white.svg',
        hoveredImg: 'assets/img/menu svg/Genset_white.svg',
        selected: false,
        expanded: false,
        permissions: '58E72A4B-DC0B-4651-A826-FE77FBE0EEFB',
        order: 0,
        id: 'm_gs',
    },
    {
        title: 'Fleet Analytics',
        routerLink: 'fleet-analytics',
        image: 'assets/img/fleet/analytics.svg',
        originalImg: 'assets/img/fleet/analytics.svg',
        hoveredImg: 'assets/img/fleet/analytics.svg',
        selected: false,
        expanded: false,
        permissions: 'F08B8107-12EB-4614-A849-D8C75BB27385',
        order: 0,
        id: 'm_fa',
        // isAnalytics: true,
        // subMenu: [
        //    { permissions: '64D170DE-0344-4FA3-8B45-9AF9A9645FCD'},
        //    { permissions:'E3A5BC36-4EF2-46D4-BE09-27D0D4DA71C5'},
        //    {permissions:'14FDB482-9C51-4234-B9E3-D75C92BBC4AA'},
        //    { permissions:'69380858-616D-43B7-9162-BBFA88FAF2A2'},
        //    { permissions:'82118438-CE9F-47B3-8E5A-3A05278CDAD3'},
        //    {permissions:'DCCDD45D-8E27-4D2F-8A1C-F8A19ABDAD50'} ]
    },
    // {
    //     title: 'Commands',
    //     routerLink: 'commands-status',
    //     image: 'assets/img/menu svg/commands.svg',
    //     originalImg: 'assets/img/menu svg/commands.svg',
    //     hoveredImg: 'assets/img/menu svg/commands.svg',
    //     selected: false,
    //     expanded: false,
    //     permissions: 'F08B8107-12EB-4614-A849-D8C75BB27385',
    //     order: 0,
    //     id: 'm_com',
    // },
    {
        title: 'Notifications',
        image: 'assets/img/menu svg/notification.svg',
        originalImg: 'assets/img/menu svg/notification.svg',
        hoveredImg: 'assets/img/menu svg/nav_icons/hover/notification_hover.svg',
        routerLink: 'notifications',
        selected: false,
        expanded: false,
        permissions: '911C0784-3B9A-443B-9047-FCC0E26504D4',
        order: 0,
        isNotification: true,
        id: 'm_not',
    },
    {
        title: 'Geofence',
        routerLink: 'pages/geofence',
        image: 'assets/img/menu svg/geo_location.svg',
        originalImg: 'assets/img/menu svg/geo_location.svg',
        hoveredImg: 'assets/img/menu svg/nav_icons/hover/geo_location_hover.svg',
        selected: false,
        expanded: false,
        permissions: 'FE137E66-E244-41F8-AD8C-0A26C68D3F57',
        order: 0,
        id: 'm_geo',
    },
    {
        title: 'Vessel Management',
        routerLink: 'app/pages/charts',
        image: 'assets/img/menu svg/ship.svg',
        originalImg: 'assets/img/menu svg/ship.svg',
        hoveredImg: 'assets/img/menu svg/ship.svg',
        selected: false,
        expanded: false,
        order: 200,
        id: 'm_vm',
        permissions: 'ALL',
        subMenu: [
            {
                title: 'Vessels',
                routerLink: 'vesselmanagement/vessels',
                permissions: '704746B6-135C-448D-AF2B-E0B4C7534909',
                id: 'vm_vs',
            },
            {
                title: 'Upload Baplie',
                routerLink: 'vesselmanagement/baplie',
                permissions: 'E9A8E933-F991-4BC2-AFED-7B8838CB9ACF',
                id: 'vm_ub',
            },
            {
                title: 'Vessel Details',
                routerLink: 'vesselmanagement/vesseldetails',
                permissions: '704746B6-135C-448D-AF2B-E0B4C7534909',
                id: 'vm_vd',
            }
        ]
    },

    {
        title: 'Reports',
        routerLink: 'charts',
        image: 'assets/img/menu svg/Reports.svg',
        originalImg: 'assets/img/menu svg/Reports.svg',
        hoveredImg: 'assets/img/menu svg/nav_icons/hover/Reports_hover.svg',
        selected: false,
        expanded: false,
        order: 200,
        permissions: ['BC0D3D84-F1EF-4E3A-BE29-ABE300880307', '86DC9975-6479-4067-9E48-FF848B9B6893', '93EC35E2-E644-4950-8E6B-A90B5AF9F44F'],
        id: 'm_rep',
        subMenu: [
            {
                title: 'Operating Hours',
                routerLink: 'pages/operations',
                permissions:'BC0D3D84-F1EF-4E3A-BE29-ABE300880307',
                id: 'm_oh_rep',
            },
            {
                title: 'Cumulative Hours',
                routerLink: 'pages/cumulative-hours',
                permissions:'86DC9975-6479-4067-9E48-FF848B9B6893',
                id: 'm_ch_rep',
            },
            {
                title: 'ISO Unit Data',
                routerLink: '',
                permissions: '93EC35E2-E644-4950-8E6B-A90B5AF9F44F',
                id: 'm_isoVol',
            }  
        ]
    },
    {
        title: 'User Management',
        routerLink: 'pages/charts',
        image: 'assets/img/menu svg/User-management.svg',
        originalImg: 'assets/img/menu svg/User-management.svg',
        hoveredImg: 'assets/img/menu svg/User-management.svg',
        selected: false,
        expanded: false,
        order: 200,
        permissions: ['4C528F6C-BC80-43A7-9C51-334876929834', 'C617D262-6FCE-42E3-88F3-FF8803557BE6'],
        id: 'm_um',
        subMenu: [
            {
                title: 'Company',
                routerLink: 'usermanagement/company',
                permissions: '4C528F6C-BC80-43A7-9C51-334876929834',
                id: 'm_um_c',
            },
            {
                title: 'Users',
                routerLink: 'usermanagement/users',
                permissions: '2B9842EE-25FF-4675-A21C-07A7AC6022F5',
                id: 'm_um_u',
            },
            {
                title: 'Roles',
                routerLink: 'usermanagement/roles',
                permissions: 'C617D262-6FCE-42E3-88F3-FF8803557BE6',
                id: 'm_um_r',
            },

        ]
    },

    {
        title: 'Container Management',
        routerLink: 'pages/charts',
        image: 'assets/img/menu svg/Asset-management.svg',
        originalImg: 'assets/img/menu svg/Asset-management.svg',
        hoveredImg: 'assets/img/menu svg/Asset-management.svg',
        selected: false,
        expanded: false,
        order: 200,
        permissions: ['2EE02AFA-626D-4767-AF07-340008EAF9DF', '01E9E32B-2C65-4EDA-BB08-DE9D963C92B1'],
        id: 'm_cm',
        subMenu: [
            {
                title: 'Devices',
                routerLink: 'containermanagement/devices',
                permissions: '2EE02AFA-626D-4767-AF07-340008EAF9DF',
                id: 'm_cm_d',
            },
            {
                title: 'Containers',
                routerLink: 'containermanagement/containers',
                permissions: '01E9E32B-2C65-4EDA-BB08-DE9D963C92B1',
                id: 'm_cm_c',
            },
            {
                title: 'Fleets',
                routerLink: 'containermanagement/fleet',
                permissions: '6399B328-2126-4CC4-BFB7-48ABF60318B2',
                id: 'm_cm_f',
            },
            {
                title: 'Device Firmware',
                routerLink: 'containermanagement/devicefirmware',
                permissions: 'E6B68D97-77AB-4407-85AA-BD34E2B55D05',
                id: 'm_cm_df',
            },
            {
                title: 'Controller Software',
                routerLink: 'containermanagement/controllersoftware',
                permissions: 'BA0ACBC4-FB5D-48CF-BD91-C0307326C8EE',
                id: 'm_cm_cs',
            },
            {
                title: 'Approve Devices',
                routerLink: 'containermanagement/approvedevices',
                permissions: '0518C66B-1477-4225-9400-B2628B454F30',
                id: 'm_cm_ad'
            },
            {
                title: 'Model Config Options',
                routerLink: 'containermanagement/modelconfig',
                permissions: '5264FD3D-225D-44A0-8E29-77CE8283472D',
                id: 'm_cm_mcos'
            }

        ]
    },
    {
        title: 'Support Team',
        routerLink: 'pages/charts',
        image: 'assets/img/menu svg/support-team.svg',
        originalImg: 'assets/img/menu svg/support-team.svg',
        hoveredImg: 'assets/img/menu svg/support-team.svg',
        selected: false,
        expanded: false,
        order: 200,
        permissions: ['14750FDF-97B2-4E46-A4E2-26C1318ED2B0'],
        id: 'su_t',
        subMenu: [
            // {
            //     title: 'Devices',
            //     routerLink: 'supportTeam/deviceSupport',
            //     permissions: 'B2D694AA-FE81-4053-84B8-94099CD7F90D',
            //     id: 'su_t_d',
            // },
            // {
            //     title: 'Containers',
            //     routerLink: 'supportTeam/containers',
            //     permissions: 'B2D694AA-FE81-4053-84B8-94099CD7F90D',
            //     id: 'su_t_c',
            // },
            {
                title: 'Container Search',
                routerLink: 'supportTeam/containerSearch',
                permissions: '14750FDF-97B2-4E46-A4E2-26C1318ED2B0',
                id: 'su_t_gs',
            }

        ]
    },
    {
        title: 'Settings',
        routerLink: 'app/pages/charts',
        image: 'assets/img/menu svg/settings.svg',
        originalImg: 'assets/img/menu svg/settings.svg',
        hoveredImg: 'assets/img/menu svg/settings.svg',
        selected: false,
        expanded: false,
        order: 200,
        permissions: 'ALL',
        id: 'm_set',
        subMenu: [
            {
                title: 'Preferences',
                routerLink: 'settings/userpreferences',
                permissions: 'ALL',
                id: 'm_set_p',
            },
            {
                title: 'Change Password',
                routerLink: 'settings/changepassword',
                permissions: '9A82A89F-D4CB-4005-A873-0D103212BF8E',
                id: 'm_set_cp',
            },
            // {
            //     title: 'Widget Settings',
            //     permissions: 'ALL'
            // },
            // {
            //     title: 'Alarm Settings',
            //     permissions: 'ALL'
            // }
        ]
    },

    // {
    //     title: 'Diagnostics',
    //     routerLink: 'diagnosticsmanagement/diagnostics',
    //     image: 'assets/img/menu svg/diagnostics.svg',
    //     originalImg: 'assets/img/menu svg/diagnostics.svg',
    //     hoveredImg: 'assets/img/menu svg/diagnostics.svg',
    //     selected: false,
    //     expanded: false,
    //     permissions: 'ALL',
    //     order: 0,
    //     //onclick: 'Logout()',
    //     id: 'm_diaog',
    // },
    {
        title: 'Logout',
        image: 'assets/img/menu svg/logout.svg',
        originalImg: 'assets/img/menu svg/logout.svg',
        hoveredImg: 'assets/img/menu svg/logout.svg',
        selected: false,
        expanded: false,
        permissions: 'ALL',
        order: 0,
        onclick: 'Logout()',
        id: 'm_log',
    }
];

export const filtermenuItems=[
    {
        title: 'Fleet',
        routerLink: 'fleet-analytics',
        image: 'assets/img/filters/fleet-black.svg',
        originalImg: 'assets/img/fleet/analytics.svg',
        hoveredImg: 'assets/img/fleet/analytics.svg',
        selectedImg: 'assets/img/filters/fleet-white.svg',
        selected: false,
        expanded: false,
        permissions: 'F08B8107-12EB-4614-A849-D8C75BB27385',
        order: 0,
        id: 'f_fl',
        
    },
    {
        title: 'Geofences',
        routerLink: 'fleet-analytics',
        image: 'assets/img/filters/geofence-black.svg',
        originalImg: 'assets/img/fleet/analytics.svg',
        hoveredImg: 'assets/img/fleet/analytics.svg',
        selectedImg: 'assets/img/filters/geofence-white.svg',
        selected: false,
        expanded: false,
        permissions: 'F08B8107-12EB-4614-A849-D8C75BB27385',
        order: 0,
        id: 'f_Geo',
        
    },
    {
        title: 'Power State',
        routerLink: 'fleet-analytics',
        image: 'assets/img/filters/powerState-black.svg',
        originalImg: 'assets/img/fleet/analytics.svg',
        hoveredImg: 'assets/img/fleet/analytics.svg',
        selectedImg: 'assets/img/filters/powerState-white.svg',
        selected: false,
        expanded: false,
        permissions: 'F08B8107-12EB-4614-A849-D8C75BB27385',
        order: 0,
        id: 'f_pwr',
        
    },
    {
        title: 'TripWISE',
        routerLink: 'fleet-analytics',
        image: 'assets/img/filters/tripwise-black.svg',
        originalImg: 'assets/img/fleet/analytics.svg',
        hoveredImg: 'assets/img/fleet/analytics.svg',
        selectedImg: 'assets/img/filters/tripwise-white.svg',
        selected: false,
        expanded: false,
        permissions: 'F08B8107-12EB-4614-A849-D8C75BB27385',
        order: 0,
        id: 'f_tri',
        
    },
    
    {
        title: 'Critical Alarms',
        routerLink: 'fleet-analytics',
        image: 'assets/img/filters/critical alarm.svg',
        originalImg: 'assets/img/fleet/analytics.svg',
        hoveredImg: 'assets/img/fleet/analytics.svg',
        selectedImg: 'assets/img/filters/critical alarm.svg',
        selected: false,
        expanded: false,
        permissions: 'F08B8107-12EB-4614-A849-D8C75BB27385',
        order: 0,
        id: 'f_ca',
        
    },
    {
        title: 'Non Critical Alarms',
        routerLink: 'fleet-analytics',
        image: 'assets/img/filters/non critical alarm.svg',
        originalImg: 'assets/img/fleet/analytics.svg',
        hoveredImg: 'assets/img/fleet/analytics.svg',
        selectedImg: 'assets/img/filters/non critical alarm.svg',
        selected: false,
        expanded: false,
        permissions: 'F08B8107-12EB-4614-A849-D8C75BB27385',
        order: 0,
        id: 'f_nca',
        
    },
    
]

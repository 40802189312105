<div *ngIf="loadRouting">
    <router-outlet></router-outlet>
 </div>
<div *ngIf="isBrowserNotCompatable">
    <div class="text-center mt-5">
        <div class="not-supported-icon">
            <i class="material-icons">error_outline</i>
        </div>
        <h3>Browser not  supported</h3>
        <div>
            This site is not fully supported in Internet Explorer. Please use Google Chrome for Web experience
        </div>
    </div>
</div>

import { Routes } from '@angular/router';
import { ErrorComponent } from './pages/error/error.component';
import { MsalCustom } from './Services/MsalCustom.service';

export const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  {path: 'app', 
    loadChildren: () => import('../app/pages/initial-page/initial.module').then(mod => mod.initialRoutes)
    ,canActivate: [MsalCustom]
  },
  { path: '**', component: ErrorComponent, 
    canActivate: [MsalCustom] 
  },
];


// import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { AppModule, appInsightsFactory, protectedResourceMap } from './app/app.module';
// import { environment } from './environments/environment';
// import { hmrBootstrap } from './hmr';
// import './polyfills';
// import { protectedResourceMap as protectedResourceMap_1 } from './app/msal/MyMsal.module';
// import { MsalComponent } from './app/msal/msal.component';
// import { AppComponent } from './app/app.component';
// import { ChartModule } from '@syncfusion/ej2-angular-charts';
// import { routing } from './app/app.routing';
// import { AgmCoreModule } from './app/pages/maps/cluster-google-maps/agm/core';
// import { provideAnimations } from '@angular/platform-browser/animations';
// import { MatTreeModule } from '@angular/material/tree';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';
// import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
// import { MatDividerModule } from '@angular/material/divider';
// import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
// import { ToastrModule } from 'ngx-toastr';
// import { MatListModule } from '@angular/material/list';
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatToolbarModule } from '@angular/material/toolbar';
// import { DialogModule } from '@syncfusion/ej2-angular-popups';
// import { MatIconModule } from '@angular/material/icon';
// import { MatSortModule } from '@angular/material/sort';
// import { TabModule } from '@syncfusion/ej2-angular-navigations';
// import { MatPaginatorModule } from '@angular/material/paginator';
// import { MatTableModule } from '@angular/material/table';
// import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
// import { SharedModule } from './app/shared.module';
// import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { UiModule } from './app/pages/ui/ui.module';
// import { RouterOutlet, provideRouter } from '@angular/router';
// import { Configuration } from 'msal';
// import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// import { MenuService } from './app/theme/components/menu/menu.service';
// import { ServerService } from './app/Core/server.service';
// import { SuccessFailureService } from './app/pages/Services/successFailure.service';
// import { AppInsightService } from './app/Services/AppInsights-Service';
// import { ToolbarService, GridModule, GridAllModule } from '@syncfusion/ej2-angular-grids';
// import { HttpServiceHelper } from './app/Core/HttpServiceHelper';
// import { DatePipe } from '@angular/common';
// import { ExceptionService } from './app/Core/exception.service';
// import { GridStatePreferencesService } from './app/Services/gridStatePreferences.service';
// import { LoadingService } from './app/Services/loading.service';
// import { UnitConverterService } from './app/Services/unit-converter.service';
// import { UserInfoService } from './app/Services/userInfo.service';
// import { NotificationService } from './app/pages/notifications/notifications.service';
// import { PermissionsService } from './app/pages/Services/permissions.service';
// import { CompanyService } from './app/pages/Services/company.service';
// import { ApiService } from './app/Services/api.service';
// import { AppConfig } from './app/app.config';
// import { HttpXsrfInterceptor, HttpXsrfTokenExtractor, HttpXsrfCookieExtractor, XSRF_COOKIE_NAME, XSRF_HEADER_NAME } from './app/shared/Interceptors/httpxrsf.interceptor';
// import { GlobalErrorHandler } from './app/Core/GlobalErrorHandler';
// import { MsalInterceptor, MsalAngularConfiguration, MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalService, BroadcastService, MsalModule } from '@azure/msal-angular';
// import { HTTP_INTERCEPTORS, HttpClient, withInterceptorsFromDi, provideHttpClient, HttpClientXsrfModule } from '@angular/common/http';
// import { MsalCustom } from './app/Services/MsalCustom.service';





// const triplinkConfig = environment.tripLinkConfig;
// function MSALAngularConfigFactory(): MsalAngularConfiguration {
//   return {
//     popUp: false,
//     consentScopes:triplinkConfig.consentScopes,
//     unprotectedResources: ['https://www.microsoft.com/en-us/'],
//     protectedResourceMap,
//     extraQueryParameters: {}
//   };
// }
// function MSALConfigFactory(): Configuration {
//   return {
//     auth: {
//       clientId: triplinkConfig.clientId,
//       authority: triplinkConfig.authority,
//       validateAuthority: triplinkConfig.validateAuthority,
//       redirectUri: triplinkConfig.redirectUri,
//       postLogoutRedirectUri: triplinkConfig.postLogoutRedirectUri,
//       navigateToLoginRequestUrl: triplinkConfig.navigateToLoginRequestUrl,
//     },
//     cache: {
//       cacheLocation: 'localStorage',
//       storeAuthStateInCookie: true  // set to true for IE 11
//     },
//   };
// }




// if (environment.production) {
//   enableProdMode();
// }
// // platformBrowserDynamic().bootstrapModule(AppModule)
// //   .catch(err => console.error(err));
// // const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)
// //  const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);


// if (environment.hmr) {
//   if (module[ 'hot' ]) {
//     // hmrBootstrap(module, bootstrap);
//   } else {
//     console.error('HMR is not enabled for webpack-dev-server!');
//     console.log('Are you using the --hmr flag for ng serve?');
//   }
// } else {
//   // bootstrap().catch(err => console.log(err));
//   if (!(window !== window.parent && !window.opener)) {
//     bootstrapApplication(AppComponent, {
//     providers: [
//         importProvidersFrom(RouterOutlet, UiModule, ReactiveFormsModule, GridModule, GridAllModule, SharedModule, TreeGridModule, FormsModule, MatTableModule, MatPaginatorModule, TabModule, MatSortModule, MatIconModule, DialogModule, MatToolbarModule, MatSidenavModule, MatListModule, ToastrModule.forRoot({
//             timeOut: 2000,
//             positionClass: 'toast-center-center',
//             preventDuplicates: true,
//         }), NgBootstrapFormValidationModule.forRoot(), NgBootstrapFormValidationModule, MatDividerModule, BrowserModule, MatInputModule, HttpClientXsrfModule.withOptions({
//             cookieName: 'XSRF-TOKEN',
//             headerName: 'X-XSRF-TOKEN',
//         }), MatFormFieldModule, MatTreeModule, AgmCoreModule, 
//         // routing,
//         // AgGridModule.withComponents([]),
//         // MsalModule.forRoot({
//         //   clientID: triplinkConfig.clientId,
//         //   authority: triplinkConfig.authority,
//         //   redirectUri: triplinkConfig.redirectUri,
//         //   cacheLocation: triplinkConfig.cacheLocation,
//         //   postLogoutRedirectUri: triplinkConfig.postLogoutRedirectUri,
//         //   validateAuthority: triplinkConfig.validateAuthority,
//         //   navigateToLoginRequestUrl: triplinkConfig.navigateToLoginRequestUrl,
//         //   popUp: triplinkConfig.popUp,
//         //   consentScopes: triplinkConfig.consentScopes,
//         //   protectedResourceMap: protectedResourceMap,
//         //   logger: loggerCallback,
//         //   correlationId: triplinkConfig.correlationId,
//         //   level: LogLevel.Info,
//         //   piiLoggingEnabled: triplinkConfig.piiLoggingEnabled,
//         // }),
//         ChartModule),
//         MsalCustom,
//         {
//             provide: HTTP_INTERCEPTORS,
//             useClass: MsalInterceptor, // This will refresh the token for (A) if it is expired
//             // as seen here https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/src/msal.interceptor.ts
//             multi: true,
//         },
//         { provide: ErrorHandler, useClass: GlobalErrorHandler },
//         { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
//         {
//             provide: HttpXsrfTokenExtractor,
//             useClass: HttpXsrfCookieExtractor,
//             multi: true,
//         },
//         { provide: XSRF_COOKIE_NAME, useValue: 'XSRF-TOKEN', multi: true },
//         { provide: XSRF_HEADER_NAME, useValue: 'X-XSRF-TOKEN', multi: true },
//         AppConfig,
//         ApiService,
//         CompanyService,
//         PermissionsService,
//         NotificationService,
//         UserInfoService,
//         UnitConverterService,
//         LoadingService,
//         GridStatePreferencesService,
//         ExceptionService,
//         HttpClient,
//         DatePipe,
//         HttpServiceHelper,
//         ToolbarService,
//         AppInsightService,
//         SuccessFailureService,
//         ServerService,
//         MenuService,
//         {
//             provide: ApplicationInsights,
//             useFactory: appInsightsFactory
//         },
//         {
//             provide: MSAL_CONFIG,
//             useFactory: MSALConfigFactory
//         },
//         {
//             provide: MSAL_CONFIG_ANGULAR,
//             useFactory: MSALAngularConfigFactory
//         },
//         MsalService,
//         BroadcastService,
//         provideHttpClient(withInterceptorsFromDi()),
//         provideAnimations(),
//         provideRouter(routing)
//     ]
// })
//     .catch(err => console.log(err));
//   } else {
//     bootstrapApplication(MsalComponent, {
//     providers: [
//         importProvidersFrom(BrowserModule, MsalModule),
//         {
//             provide: MSAL_CONFIG,
//             useFactory: MSALConfigFactory
//         },
//         {
//             provide: MSAL_CONFIG_ANGULAR,
//             useFactory: MSALAngularConfigFactory
//         },
//         provideRouter([{ path: '', component: MsalComponent }, { path: '**', redirectTo: '' }])
//     ]
// })
//     .catch(err => console.log(err));
//   }
// }

// import { bootstrapApplication } from '@angular/platform-browser';
// import { AppComponent } from './app/app.component';
// bootstrapApplication(AppComponent).catch(e => console.error(e));

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5bdkdjXnpWdHdXT2df');
bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));

import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  provideRouter,
  RouterModule,
  RouterOutlet,
  withRouterConfig,
} from '@angular/router';

import { routes } from './app.routes';
import { ApiService } from './Services/api.service';
import { AppInsightService } from './Services/AppInsights-Service';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { LoadingService } from './Services/loading.service';
import { TranslationModule } from '../language-translation/translation.module';
import { CommonModule, DatePipe } from '@angular/common';
import { TreeviewModule } from 'ngx-treeview';
import { DialogModule } from '@syncfusion/ej2-angular-popups';

import {
  GridAllModule,
  GridComponent,
  GridModule
} from '@syncfusion/ej2-angular-grids';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalCustom } from './Services/MsalCustom.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import {
  BrowserAnimationsModule,
  provideNoopAnimations,
} from '@angular/platform-browser/animations';
import { UnitConverterService } from './Services/unit-converter.service';
import {
  TranslatePipe,
  TranslateService,
  TRANSLATION_PROVIDERS,
} from '../language-translation';
import { MenuService } from './theme/components/menu/menu.service';
import { DashboardService } from './pages/dashboard/dashboard.service';
import { SuccessFailureService } from './pages/Services/successFailure.service';
import { CompanyService } from './pages/Services/company.service';
import { NotificationService } from './pages/notifications/notifications.service';
import { environment } from '../environments/environment';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { CancelSameApisInterceptor } from './Services/CancelSameApisInterceptor';
import { msalConfig } from './auth.config';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

  export function loggerCallback(logLevel: LogLevel, message: string) {
    // console.log(message);
  }
const triplinkConfig = environment.tripLinkConfig;
const triplinkApiConfig = environment.apiConfig;

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection(),
    provideRouter(routes,withRouterConfig({
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload'
    }),),
    RouterOutlet,
    RouterModule,
    CommonModule,
    HttpClient,
    // GoogleMapsModule,
    provideHttpClient(),
    LoadingService,
    MsalService,
    provideNoopAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: HTTP_INTERCEPTORS, useClass: CancelSameApisInterceptor, multi: true },
    ApiService,
    SuccessFailureService,
    ToastrService,
    importProvidersFrom(
      MatSelectCountryModule.forRoot('en'),
      ToastrModule.forRoot(),
      TreeviewModule.forRoot(),
    ),
    UnitConverterService,
    TranslateService,
    TRANSLATION_PROVIDERS,
    CompanyService,
    AppInsightService,
    DatePipe,
    MenuService,
    NotificationService,
    TranslatePipe,
    GridModule,
    BrowserAnimationsModule,
    DashboardService,
    GridAllModule,
    GridComponent,
    TreeviewModule,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalCustom,
    //-------agmmap
    TranslationModule,
    // AgmJsMarkerClustererModule,
    DialogModule,
    // RadioButtonModule,
    // ButtonModule,
    //-----
    // AppInsightService,
    // LoadingService,
    // // MsalService,
    // MenuService,
    // DashboardService,
    // BrowserModule,
    // ServerService,
    // UserInfoService,
    // HttpClient,
    // ExceptionService,
    // GridStatePreferencesService,
    // AppState,
    // SuccessFailureService,
    // ToastrService,
    // //
    // // NgxFlagPickerModule,
    // TranslationModule,
    // CommonModule,
    // MatDialogModule,
    // FormsModule,
    // ReactiveFormsModule,
    // ,
    // UnitConverterService,
    // TranslateService,
    // // TreeviewModule.forRoot(),
    // provideToastr(),
    // provideAnimations(),
    // // TooltipModule,
    // TextBoxModule,
    // DropDownListAllModule,
    // Ng2TelInputModule,
    // MatPaginatorModule,
    // MatTableModule,
    // GridModule,
    // // SharedDialogsModule,
    // MatDialogModule,
    // DatePickerAllModule,
    // TabModule,
    // NumericTextBoxAllModule,
    // CheckBoxModule,
    // SwitchAllModule,
    // UploaderAllModule,
    // GridAllModule,
    // ChipListModule,
    // ButtonAllModule,
    // TimePickerModule,
    // // MsalCustom,
    // // PageService,
    // //     EditService,
    // //     ReorderService,
    // //     ColumnChooserService,
    // //     ExcelExportService,
    // //     PdfExportService,
    // //     ResizeService,
    // //     SortService,
    // //     FreezeService,
    // //     FilterService,
    // //     SearchService,
    // //     ToolbarService,
    // //     GroupService,
    // //     ContextMenuService,
    // provideHttpClient(),
    // provideClientHydration(),

    //   TRANSLATION_PROVIDERS,
    //   MsalService,
    //   MsalGuard,
    //   MsalBroadcastService,
    //   importProvidersFrom(TooltipModule, DialogModule,TreeviewModule.forRoot(),
    //   PopoverModule.forRoot()),

    //     MatDialogModule,
    //     FormsModule,
    //     ReactiveFormsModule,
    //     TranslationModule,
    //     TranslatePipe,
    //     UiModule,

    //     IndeterminateDirective
  ],
};
// export function MSALInstanceFactory(): IPublicClientApplication {
//   return new PublicClientApplication({
//     auth: {
//       clientId: triplinkConfig.clientId,
//       authority: triplinkConfig.authority,//'https://login.microsoftonline.com/triplinkuat.onmicrosoft.com',
//       redirectUri: triplinkConfig.redirectUri,
//       postLogoutRedirectUri:triplinkConfig.redirectUri,
//       knownAuthorities: [triplinkConfig.knowAuth],// ["triplinkdev.b2clogin.com"], // array of URIs that are known to be valid,
//       navigateToLoginRequestUrl: true,
//       // clientId: '3318f935-b137-4e50-92a9-f8b3a7aab0c8',
//       // authority:  'https://triplinkdev.b2clogin.com/triplinkdev.onmicrosoft.com/B2C_1_triplinkdevsignupin',
//      // redirectUri: triplinkConfig.redirectUri,
//      // knownAuthorities: ['triplinkdev.b2clogin.com'], // array of URIs that are known to be valid,
//      // navigateToLoginRequestUrl: true,
//     },
//     cache: {
//       cacheLocation: BrowserCacheLocation.LocalStorage,
//       storeAuthStateInCookie: true, // set to true for IE 11
//           },
//     system: {
//       allowNativeBroker: false, // Disables WAM Broker
//       loggerOptions: {
//         loggerCallback:()=>{},
//         logLevel: LogLevel.Info,
//         piiLoggingEnabled: false,
//       },
//     },
//   }
//  );
// }
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

// provides authRequest configuration
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(triplinkConfig.redirectUri, [
    'openid',
    'profile',
    'offline_access',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['openid', 'profile', 'offline_access'],
    },
  };
}
